import { Component } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `@for (field of field.fieldGroup; track field) {
    <formly-field [field]="field"></formly-field>
  } `,
  standalone: true,
  imports: [FormlyModule],
})
export class FormlyRepeatSectionComponent extends FieldArrayType {}
