import { Component } from '@angular/core';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { MatInput } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'formly-field-textarea',
  template: `
    <textarea
      matInput
      [attr.data-test-id]="field.key"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [rows]="props.rows"
    ></textarea>
  `,
  standalone: true,
  imports: [MatInput, ReactiveFormsModule, FormlyModule],
})
export class FormlyFieldTextAreaComponent extends FieldType<FieldTypeConfig> {}
