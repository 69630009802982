<mat-form-field class="search-input">
  <input
    matInput
    id="searchProperties"
    [required]="required"
    [matAutocomplete]="auto"
    [placeholder]="'shared.proca.propertySearch.placeholder' | translation"
    [formControl]="propertySearch"
  />

  @if (!readOnly && !disabled) {
    <div class="suffix-area" matSuffix>
      @if (pending) {
        <mat-spinner [diameter]="18" [strokeWidth]="3"></mat-spinner>
      }
      <button mat-icon-button (click)="clearPropertySearch()">
        <mat-icon svgIcon="ak-close"></mat-icon>
      </button>
    </div>
  }

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event.option.value)">
    @for (property of properties; track property) {
      <mat-option [value]="property">
        {{ getCustomPropertyName(property) }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
@if (selectedProperties?.length) {
  <div class="selected-options-container">
    @for (selectedProperty of selectedProperties; track selectedProperty) {
      <button mat-flat-button data-test-selected-property [disabled]="disabled" (click)="onDeselect(selectedProperty)">
        <span>{{ selectedProperty.name }}</span>
        @if (!disabled) {
          <mat-icon svgIcon="ak-close"></mat-icon>
        }
      </button>
    }
  </div>
}
