import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { MatStepper, MatStep, MatStepLabel, MatStepperPrevious, MatStepperNext } from '@angular/material/stepper';
import { MatButton } from '@angular/material/button';
import { TranslationPipe } from '../../translation/pipes/translation/translation.pipe';

@UntilDestroy()
@Component({
  selector: 'formly-file-specific-repeat',
  styleUrls: ['./file-upload-stepper-type.component.scss'],
  template: `
    <mat-horizontal-stepper labelPosition="bottom" linear [@.disabled]="true">
      @for (step of field.fieldGroup; track identify(index, step); let index = $index; let last = $last) {
        <mat-step [stepControl]="step.formControl">
          <ng-template matStepLabel> {{ step.props.label | translation }}</ng-template>
          <formly-field [field]="step"></formly-field>
          <div>
            <div class="action-bar">
              @if (index !== 0) {
                <button type="button" matStepperPrevious mat-flat-button data-test-back-button (click)="previousStep()">
                  {{ 'core.button.back' | translation }}
                </button>
              }
              @if (!last) {
                <button
                  type="button"
                  matStepperNext
                  mat-flat-button
                  data-test-next-button
                  (click)="nextStep()"
                  [disabled]="step.formControl.invalid"
                >
                  {{ 'core.button.next' | translation }}
                </button>
              }
              @if (last) {
                <button
                  class="btn btn-primary"
                  mat-flat-button
                  data-test-submit-button
                  (click)="submit()"
                  [disabled]="form.invalid"
                  [color]="'primary'"
                >
                  {{ 'core.button.submit' | translation }}
                </button>
              }
            </div>
          </div>
        </mat-step>
      }
    </mat-horizontal-stepper>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatStepper,
    MatStep,
    MatStepLabel,
    FormlyModule,
    MatStepperPrevious,
    MatButton,
    MatStepperNext,
    TranslationPipe,
  ],
})
export class FormlyFileUploadStepperComponent extends FieldType<FieldTypeConfig> implements OnDestroy {
  currentStep$ = new BehaviorSubject(0);
  defaultOptions = { defaultValue: {} };

  identify(index: number, step: FormlyFieldConfig) {
    return step.key;
  }

  submit() {
    this.field?.props?.submit();
  }

  previousStep() {
    this.currentStep$.next(this.currentStep$.value - 1);
  }

  nextStep() {
    this.currentStep$.next(this.currentStep$.value + 1);
  }

  public ngOnDestroy(): void {}
}
