import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'formly-field-radio-group',
  styleUrls: ['./radio-group.component.scss'],
  template: `
    <mat-radio-group [formControl]="formControl" [formlyAttributes]="field" [attr.data-test-id]="field.key">
      @for (option of getOptions(props.options) | async; track option) {
        <mat-radio-button [value]="option.value" [checked]="option.checked" [disabled]="option.disabled">
          <span class="button-label" [ngClass]="{ 'is-inactive': option.inactive }">
            @if (option.icon) {
              <mat-icon [svgIcon]="option.icon"></mat-icon>
            }
            {{ option.title }}
          </span>
        </mat-radio-button>
      }
    </mat-radio-group>
  `,
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule, MatRadioButton, MatIcon, CommonModule, MatRadioModule],
})
export class FormlyRadioGroupComponent extends FieldType<FieldTypeConfig> {
  getOptions(options: any[] | Observable<any[]> | undefined): Observable<any[]> {
    if (!options) {
      return of([]);
    }

    return options instanceof Observable ? options : of(options);
  }
}
