import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';

// rxjs
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'formly-field-debounced-text-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormlyFieldDebouncedTextComponent),
      multi: true,
    },
  ],
  template: `
    <mat-form-field>
      <mat-label>{{ to.label }}</mat-label>
      <input
        matInput
        [value]="interimValue | async"
        [formlyAttributes]="field"
        (keyup)="searchTerm$.next($event.target!['value'])"
      />
      @if ((interimValue | async) !== '' && (interimValue | async) !== null) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="onClearClicked()">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  `,
  standalone: true,
  imports: [MatFormField, MatLabel, MatInput, FormlyModule, MatIconButton, MatSuffix, MatIcon, AsyncPipe],
})
export class FormlyFieldDebouncedTextComponent implements OnDestroy, ControlValueAccessor {
  @Input() to: NonNullable<FormlyFieldConfig['props']>;
  @Input() field: FormlyFieldConfig;

  searchTerm$ = new Subject<string>();
  interimValue = new BehaviorSubject<string | null>('');
  onChange: (_: unknown) => void;
  onTouch: (_: unknown) => void;

  writeValue(value: string) {
    this.searchTerm$.next(value);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  constructor() {
    this.searchTerm$
      .pipe(distinctUntilChanged(), tap({ next: (val) => this.interimValue.next(val) }), debounceTime(800))
      .subscribe({
        next: (value) => {
          this.onChange(value);
        },
      });
  }

  ngOnDestroy(): void {
    this.searchTerm$.complete();
  }

  onClearClicked() {
    this.searchTerm$.next(''); // we update the search term since there may be outstanding debounced updates
    this.onChange('');
  }
}

@Component({
  selector: 'formly-field-custom-input',
  template: `<formly-field-debounced-text-input
    [to]="props"
    [field]="field"
    [formControl]="formControl"
  ></formly-field-debounced-text-input>`,
  standalone: true,
  imports: [FormlyFieldDebouncedTextComponent, ReactiveFormsModule],
})
export class FormlyFieldTextFilterComponent extends FieldType<FieldTypeConfig> {}
