import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-file-specific-panel',
  styleUrls: ['./file-specific-panel.component.scss'],
  template: `
    <div class="panel-wrapper">
      <h4 class="file-name">{{ props.label }}</h4>
      <div>
        <ng-container #fieldComponent></ng-container>
      </div>
    </div>
  `,
  standalone: true,
})
export class FileSpecificPanelComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent!: ViewContainerRef;
}
