import { Component, Input, Output, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/core';
import { TranslationPipe } from '../../../translation/pipes/translation/translation.pipe';

export interface AutocompleteItem {
  label: string;
  value: string;
}

const AUTO_COMPLETE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutoCompleteComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [AUTO_COMPLETE_ACCESSOR],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatSuffix,
    MatProgressSpinner,
    MatIconButton,
    MatIcon,
    MatAutocomplete,
    MatOption,
    TranslationPipe,
  ],
})
export class AutoCompleteComponent implements OnInit, ControlValueAccessor {
  @Input() loading: boolean;
  @Input() selectedItem$: Observable<AutocompleteItem>;
  @Input() items: AutocompleteItem[] = [];
  @Input() placeholder: string;

  @Output() searchTextChanged = new EventEmitter<string>();

  value: string | null = null;
  searchText = new UntypedFormControl();

  onTouch: () => void = () => {};
  onModelChange: (value: string | null) => void = () => {};

  registerOnChange(fn: (value: string | null) => void) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  writeValue(value: string | null) {
    this.value = value;
    if (!value) {
      this.searchText.setValue('', { emitEvent: false });
    }
  }

  updateModel(value: string | null = null) {
    this.writeValue(value);
    this.onTouch();
    this.onModelChange(value);
  }

  ngOnInit() {
    this.selectedItem$
      ?.pipe(
        untilDestroyed(this),
        filter((item) => !!item),
      )
      .subscribe({
        next: (item: AutocompleteItem) => {
          this.searchText.setValue(item, { emitEvent: false });
        },
      });

    this.searchText.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((searchText) => !this.isAutocompleteItem(searchText) && searchText.trim().length >= 2),
        debounceTime(400),
      )
      .subscribe({
        next: (searchText) => this.searchTextChanged.emit(searchText),
      });
  }

  onSelect(item: AutocompleteItem) {
    this.updateModel(item?.value);
  }

  getDisplayValue(item: AutocompleteItem) {
    return item?.label || null;
  }

  clear() {
    this.updateModel('');
    this.searchText.setValue('', { emitEvent: false });
    this.searchTextChanged.emit('');
  }

  private isAutocompleteItem = (event: AutocompleteItem): event is AutocompleteItem => {
    return event?.value !== undefined;
  };
}
