import { importProvidersFrom, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { NativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import * as fromTypes from './types';
import * as fromComponents from './components';
import * as fromWrappers from './wrapper';
import { registerValidationMessagesExtension } from './extensions/validation-messages.extension';
import { TranslationService } from '../../../core/services/translation.service';
import { FileDropzoneModule, InputFileModule } from '@akelius/angular-ui-kit';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { CUSTOM_FORMLY_CONFIG } from './config';

@NgModule({
  imports: [
    CommonModule,
    MatStepperModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    NativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatMomentDateModule,
    InputFileModule,
    FileDropzoneModule,
    FormlyModule,
    ScrollingModule,
    FormlyModule.forRoot(CUSTOM_FORMLY_CONFIG),
    ...fromTypes.components,
    ...fromWrappers.components,
    ...fromComponents.components,
  ],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerValidationMessagesExtension,
      deps: [TranslationService],
    },
  ],
  exports: [FormlyModule, ...fromComponents.components],
})
export class UiFormlyModule {}

export function provideFormly() {
  return importProvidersFrom(UiFormlyModule);
}
