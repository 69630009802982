import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldTypeConfig, FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-move-unknown-type-wrapper',
  styles: [
    `
      .wrapper-content {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .wrapper-content > * {
        flex: 0 0 50%;
      }

      .field-component {
        width: 100%;
      }
    `,
  ],
  template: `
    <div class="wrapper-content">
      <div>{{ to.displayName || '-' }}</div>
      <div class="field-component"><ng-container #fieldComponent></ng-container></div>
    </div>
  `,
  standalone: true,
})
export class MoveUnknownTypeWrapperComponent extends FieldWrapper<FieldTypeConfig> {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent: ViewContainerRef;
}
