import {
  AfterViewInit,
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
  inject,
} from '@angular/core';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

import { TreeComponent } from '../tree/tree.component';

import { ItemFlatNode, TreeNode } from '../../models';

import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgTemplateOutlet, NgClass } from '@angular/common';

const SELECTION_TREE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectionTreeComponent),
  multi: true,
};

/**
 * @title Tree with checkboxes
 */
@UntilDestroy()
@Component({
  selector: 'app-selection-tree',
  templateUrl: './selection-tree.component.html',
  providers: [SELECTION_TREE_ACCESSOR],
  styleUrls: ['./selection-tree.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, TreeComponent, MatCheckbox, NgTemplateOutlet, NgClass],
})
export class SelectionTreeComponent<N, G> implements AfterViewInit, OnDestroy, ControlValueAccessor {
  private _formBuilder = inject(UntypedFormBuilder);
  private cdr = inject(ChangeDetectorRef);

  @Input() parentNodeTemplate: TemplateRef<any>;
  @Input() leafNodeTemplate: TemplateRef<any>;
  @Input() propagateSelection = true;
  @Input() treeDefinition: TreeNode<N, G>;

  @Output() selectedAssetsEvent = new EventEmitter<string[]>();

  @ViewChild('treeComponent', { static: true }) treeComponent: TreeComponent<N, G>;
  form = new UntypedFormGroup({});

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode<N, G>>(true /* multiple */);

  /** ControlValueAccessor **/
  private value: string[] = [];
  private onTouch: () => void = () => {};
  private onModelChange: (value: string[] | null) => void = () => {};

  registerOnChange(fn: (value: string[] | null) => void) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  writeValue(value: string[]) {
    setTimeout(() => {
      value.forEach((asset) => {
        const selectedNode = this.treeComponent.flatNodes.find((node) => asset === node.id);
        if (selectedNode) {
          this.checklistSelection.select(selectedNode);
          this._expandParentNodes(selectedNode);
        }
      });
      this.cdr.detectChanges();
    });
  }

  onSelectedAssets(selectedAssets: string[]) {
    this.value = selectedAssets;
    this.onTouch();
    this.onModelChange(this.value);
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.treeComponent.treeDatabase.dataChange.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.form = this._formBuilder.group(this.formFields);

        this.form.valueChanges
          .pipe(
            untilDestroyed(this),
            debounceTime(100),
            distinctUntilChanged(),
            map(this.mapSelectedFormItems.bind(this)),
          )
          .subscribe({
            next: (selectedItems: string[]) => {
              this.selectedAssetsEvent.emit(selectedItems);
              this.onSelectedAssets(selectedItems);
            },
          });

        this.checklistSelection.changed.subscribe(this.onChecklistSelectionChanged.bind(this));
      },
    });
  }

  private onChecklistSelectionChanged(value: SelectionChange<ItemFlatNode<N, G>>) {
    value.added.forEach((addedItem: ItemFlatNode<N, G>) => {
      if (this.form.controls[addedItem.id]) {
        this.form.controls[addedItem.id].setValue(true);
      }
    });

    value.removed.forEach((addedItem) => {
      if (this.form.controls[addedItem.id]) {
        this.form.controls[addedItem.id].setValue(false);
      }
    });
  }

  private get formFields() {
    return this.treeComponent.flatNodes
      .filter((item) => !item.isGroup)
      .reduce((items, item) => ({ ...items, [item.id]: [false] }), {});
  }

  private mapSelectedFormItems(values: { [key: string]: boolean }): string[] {
    return Object.keys(values).reduce((array: string[], id: string) => {
      if (values[id]) {
        array.push(id);
      }
      return array;
    }, []);
  }

  private directDescendantsAllSelected(node: ItemFlatNode<N, G>): boolean {
    const directDescendants = this.getDirectDescendants(node);
    return directDescendants.every((child) => this.checklistSelection.isSelected(child));
  }

  private getDirectDescendants(node: ItemFlatNode<N, G>) {
    const descendants = this.treeComponent.treeControl.getDescendants(node);
    return descendants.filter((descendant) => descendant.level === node.level + 1);
  }

  /** Whether all the descendants of the node are selected. */
  private descendantsAllSelected(node: ItemFlatNode<N, G>): boolean {
    const descendants = this.propagateSelection
      ? this.treeComponent.treeControl.getDescendants(node)
      : this.getDirectDescendants(node);
    return descendants.every((child) => this.checklistSelection.isSelected(child));
  }

  private checkDirectParentSelection(node: ItemFlatNode<N, G>): void {
    const parentNode = this.getParentNode(node);
    if (parentNode) {
      this.checkRootNodeSelection(parentNode);
    }
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  private checkAllParentsSelection(node: ItemFlatNode<N, G>): void {
    let parent: ItemFlatNode<N, G> | null = this.getParentNode(node);

    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  private checkRootNodeSelection(node: ItemFlatNode<N, G>): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.propagateSelection
      ? this.treeComponent.treeControl.getDescendants(node)
      : this.getDirectDescendants(node);

    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));

    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ItemFlatNode<N, G>): ItemFlatNode<N, G> | null {
    if (!node) {
      return null;
    }
    const currentLevel = this.treeComponent.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeComponent.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeComponent.treeControl.dataNodes[i];

      if (this.treeComponent.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ItemFlatNode<N, G>): boolean {
    const descendants = this.propagateSelection
      ? this.treeComponent.treeControl.getDescendants(node)
      : this.getDirectDescendants(node);

    const result = descendants.some((child) => this.checklistSelection.isSelected(child));

    return result && !this.descendantsAllSelected(node);
  }

  isChecked(node: ItemFlatNode<N, G>) {
    if (this.propagateSelection) {
      return this.descendantsAllSelected(node);
    } else {
      return node.isGroup ? this.directDescendantsAllSelected(node) : this.isSelected(node);
    }
  }

  isSelected(node: ItemFlatNode<N, G>): boolean {
    return this.checklistSelection.isSelected(node);
  }

  groupSelectionToggle(node: ItemFlatNode<N, G>): void {
    this.checklistSelection.toggle(node);
    const directDescendants = this.getDirectDescendants(node);

    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...directDescendants)
      : this.checklistSelection.deselect(...directDescendants);
  }

  /** Toggle the item selection. Select/deselect all the descendants node */
  itemSelectionToggle(node: ItemFlatNode<N, G>): void {
    this.checklistSelection.toggle(node);

    if (this.propagateSelection) {
      const descendants = this.treeComponent.treeControl.getDescendants(node);

      this.checklistSelection.isSelected(node)
        ? this.checklistSelection.select(...descendants)
        : this.checklistSelection.deselect(...descendants);

      // Force update for the parent
      descendants.every((child) => this.checklistSelection.isSelected(child));
      this.checkAllParentsSelection(node);
    } else if (!node.isGroup) {
      this.checkDirectParentSelection(node);
    }
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: ItemFlatNode<N, G>): void {
    this.checklistSelection.toggle(node);
    if (this.propagateSelection) {
      this.checkAllParentsSelection(node);
    } else if (!node.isGroup) {
      this.checkDirectParentSelection(node);
    }
  }

  private _expandParentNodes(node: ItemFlatNode<N, G>) {
    const parentNode = this.getParentNode(node);
    if (parentNode) {
      this.treeComponent.expandNode(parentNode.id);
      const nestedParentNode = this.getParentNode(parentNode);
      nestedParentNode && this._expandParentNodes(nestedParentNode);
    }
  }
}
