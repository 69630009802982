import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FieldSuffixType } from '../models';
import { HelpTextIconComponent } from '../../../core/components/help-text-icon/help-text-icon.component';

@Component({
  selector: 'formly-wrapper-addons',
  styles: [
    ':host { display: flex; align-items: flex-start; width: 100%; justify-content: stretch }',
    '.field { flex: 1; }',
    '.field { flex: 1; }',
  ],
  template: `
    <div class="field">
      <ng-container #fieldComponent></ng-container>
    </div>
    @if (props.addonRight?.type === fieldSuffixType.helpIcon) {
      <app-help-text-icon
        style="margin: 4px"
        [declaredValue]="props.addonRight.title"
        [helpTextKey]="props.addonRight.text"
        class="help-icon"
      >
      </app-help-text-icon>
    }
  `,
  standalone: true,
  imports: [HelpTextIconComponent],
})
export class FieldSuffixComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent!: ViewContainerRef;

  fieldSuffixType = FieldSuffixType;
}
