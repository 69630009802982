<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <input matInput #searchTextEl [matAutocomplete]="auto" [formControl]="searchText" />

  <div class="suffix-area" matSuffix>
    @if (loading) {
      <mat-spinner [diameter]="18" [strokeWidth]="3"></mat-spinner>
    }
    @if (searchTextEl.value) {
      <button mat-icon-button (click)="clear()">
        <mat-icon svgIcon="ak-close"></mat-icon>
      </button>
    }
  </div>

  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelect($event.option.value)"
    [displayWith]="getDisplayValue"
  >
    @for (item of items; track item) {
      <mat-option [value]="item" [innerHTML]="item.label"></mat-option>
    }
    @if (!items?.length) {
      <mat-option disabled [innerHTML]="'dynamic.node.filter.group.noData' | translation"></mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
