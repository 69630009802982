<form class="ak-form-container full-width-form" [formGroup]="form">
  <app-property-search-picker
    [pending]="pending"
    [properties]="properties"
    [readOnly]="isReadOnly"
    [required]="formlyAttributes?.props?.required"
    (searchTextChange)="onSearchTextChange($event)"
    formControlName="properties"
  >
  </app-property-search-picker>

  @if (!disabled && showVisualiseBtn) {
    <button (click)="visualise()" class="visualise-btn" mat-flat-button color="primary">
      {{
        (showTree
          ? 'shared.proca.assetSelector.message.selectAnotherProperty'
          : 'shared.proca.assetSelector.message.selectPropertyAssets'
        ) | translation
      }}
    </button>
  }

  @if (!disabled && loading) {
    <mat-spinner></mat-spinner>
  }

  @if (treeDefinition) {
    <cdk-virtual-scroll-viewport itemSize>
      <app-selection-tree
        [treeDefinition]="treeDefinition"
        [parentNodeTemplate]="selectionLabel"
        [leafNodeTemplate]="selectionLabel"
        [propagateSelection]="false"
        formControlName="childAssets"
      >
      </app-selection-tree>
    </cdk-virtual-scroll-viewport>
    <ng-template #selectionLabel let-node>
      @if (node.isGroup) {
        <span>{{ node.data?.label | translation }} ({{ node.childCount }})</span>
      }
      @if (!node.isGroup) {
        <span>{{ node.data?.displayName }}</span>
      }
    </ng-template>
  }
</form>
