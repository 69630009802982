import { Component, OnInit, inject } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { PropertyAssetSelectorService } from '../services';
import { NotificationService } from '../../../../core/services/notification.service';
import { TreeNode } from '../../tree/models';
import { PropertyResourceListEntity } from '../models/property-asset-search.interafces';
import { MinimalAssetTreeNode } from '../../../../features/property-catalog/models/interfaces';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { PropertyAssetSelectorComponent } from '../components/property-asset-selector/property-asset-selector.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { NotFoundError } from '../../../../core/common/not-found-error';

@Component({
  selector: 'formly-property-asset-selector',
  template: `
    @if (assetName) {
      <div class="selected-option">{{ assetName }}</div>
    } @else {
      <app-property-asset-selector
        [formControl]="formControl"
        [formlyAttributes]="field"
        [loading]="loading"
        [pending]="pending"
        [assetSelection]="field.props?.assetSelection"
        [properties]="properties$ | async"
        [treeDefinition]="treeDefinition$ | async"
        (search)="search($event)"
        (loadTree)="loadTree($event)"
      ></app-property-asset-selector>
      @if (showError) {
        <formly-validation-message [class.error]="showError" [field]="field"></formly-validation-message>
      }
    }
  `,
  styles: [
    `
      .error {
        color: #c4401a;
      }

      .selected-option {
        margin: 0 0 20px 0;
        display: inline-block;
        background-color: #a3c3d4 !important;
        color: #000;
        border-radius: 3px;
        padding: 12px 8px;
      }
    `,
  ],
  standalone: true,
  imports: [PropertyAssetSelectorComponent, ReactiveFormsModule, FormlyModule, AsyncPipe],
})
export class FormlyPropertyAssetSelectorComponent extends FieldType<FieldTypeConfig> implements OnInit {
  private notificationService = inject(NotificationService);
  private propertyAssetSelectorService = inject(PropertyAssetSelectorService);

  loading: boolean;
  pending: boolean;
  assetName: string;
  properties$: Observable<PropertyResourceListEntity[]>;
  treeDefinition$: Observable<TreeNode<MinimalAssetTreeNode, { label: string }>> | null;

  ngOnInit() {
    const queryParam = this.field.props?.assetSelection?.queryParam;

    if (queryParam) {
      this.loadAssetInfoByObjectId(queryParam);
    }
  }

  public search(searchTerm: string): void {
    this.pending = true;
    this.properties$ = this.propertyAssetSelectorService
      .searchProperties(searchTerm)
      .pipe(tap({ next: (_) => (this.pending = false) }));
  }

  loadTree(objectId: string): void {
    if (objectId) {
      this.loading = true;
      this.treeDefinition$ = this.propertyAssetSelectorService.loadTreeByObjectId(objectId).pipe(
        tap({ next: (_) => (this.loading = false) }),
        catchError((error) => {
          if (error instanceof NotFoundError) {
            this.notificationService.showErrorDialog('forms.assetSelector.error.propertyNotFound');
            return EMPTY;
          } else {
            throw error;
          }
        }),
      );
    } else {
      this.loading = false;
      this.treeDefinition$ = null;
    }
  }

  private loadAssetInfoByObjectId(objId: string): void {
    this.propertyAssetSelectorService
      .loadAssetInfoByObjectId(objId)
      .pipe(
        take(1),
        tap({ next: (asset: any) => (this.assetName = asset.displayName) }),
        catchError((error) => {
          this.value = [];
          if (error instanceof NotFoundError) {
            this.notificationService.showErrorDialog('forms.assetSelector.error.propertyNotFound');
            return EMPTY;
          } else {
            throw error;
          }
        }),
      )
      .subscribe();
  }
}
