import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'formly-field-button',
  template: `
    <div>
      <button [type]="props.type" mat-flat-button (click)="onClick()" [attr.data-test-id]="props.testId">
        {{ props.text }}
      </button>
    </div>
  `,
  standalone: true,
  imports: [MatButton],
})
export class FormlyButtonComponent extends FieldType<FieldTypeConfig> {
  onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.form, this.options);
    }
  }
}
