import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { TreeNode } from '../../../tree/models';
import { AssetSelection, MinimalAssetTreeNode } from '../../../../../features/property-catalog/models/interfaces';
import { PropertyResourceListEntity } from '../../models/property-asset-search.interafces';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { FieldTypeConfig } from '@ngx-formly/core';
import { PropertySearchPickerComponent } from '../property-search-picker/property-search-picker.component';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll } from '@angular/cdk/scrolling';
import { SelectionTreeComponent } from '../../../tree/components/selection-tree/selection-tree.component';
import { TranslationPipe } from '../../../translation/pipes/translation/translation.pipe';

interface Data {
  label: string;
}

const ASSET_SELECTOR_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PropertyAssetSelectorComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-property-asset-selector',
  templateUrl: './property-asset-selector.component.html',
  styleUrls: ['./property-asset-selector.component.scss'],
  providers: [ASSET_SELECTOR_ACCESSOR],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PropertySearchPickerComponent,
    MatButton,
    MatProgressSpinner,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    SelectionTreeComponent,
    TranslationPipe,
  ],
})
export class PropertyAssetSelectorComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
  private fb = inject(UntypedFormBuilder);

  @Input() pending = false;
  @Input() loading = false;
  @Input() assetSelection: AssetSelection = { properties: [], childAssets: [] };
  @Input() properties: PropertyResourceListEntity[] = [];
  @Input() treeDefinition: TreeNode<MinimalAssetTreeNode, Data>;
  @Input() formlyAttributes: FieldTypeConfig;

  @Output() search = new EventEmitter<string>();
  @Output() loadTree = new EventEmitter<string | null>();

  form = this.fb.group({
    properties: [[]],
    childAssets: [[]],
  });

  showTree = false;

  value: string[] = [];
  disabled = false;
  onTouched: () => void = () => {};
  onModelChanged: (value: string[] | null) => void = () => {};

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(this.onFormValueChanges.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['assetSelection'] && changes['assetSelection'].currentValue) {
      const { properties, childAssets } = changes['assetSelection'].currentValue;

      (this.form.get('properties') as AbstractControl).setValue(properties);
      (this.form.get('childAssets') as AbstractControl).setValue(childAssets);

      if (properties && properties.length === 1) {
        setTimeout(() => this.visualise());
      }
    }
  }

  private onFormValueChanges(assetSelection: AssetSelection) {
    const objectIds: string[] = this.mapAssetSelectionToObjectIds(assetSelection);

    if (assetSelection.properties.length !== 1 || objectIds.length === 0) {
      this.showTree = false;
      this.loadTree.emit(null);
    } else if (assetSelection.properties.length === 1 && !this.showTree) {
      this.visualise();
    }

    this.updateValue(objectIds);
  }

  private mapAssetSelectionToObjectIds(assetSelection: AssetSelection): string[] {
    const { properties, childAssets } = assetSelection;
    let objectIds = properties.map((property) => property.id);
    if (properties.length === 1) {
      if (childAssets.length > 0) {
        objectIds = [...childAssets];
      }
    } else {
      (this.form.get('childAssets') as AbstractControl).setValue([], { emitEvent: false });
    }
    return objectIds;
  }

  get showVisualiseBtn() {
    return this.propertiesFormControl.value ? this.propertiesFormControl.value.length === 1 : false;
  }

  get propertiesFormControl() {
    return this.form.get('properties') as UntypedFormControl;
  }

  get isReadOnly() {
    return this.disabled || !!(this.treeDefinition || this.loading);
  }

  registerOnChange(fn: (value: string[] | null) => void) {
    this.onModelChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.disabled = isDisabled;
  }

  writeValue(objectIds: string[]) {
    if (!objectIds) {
      this.form.patchValue({ properties: [], childAssets: [] });
    }
    this.value = objectIds;
  }

  updateValue(objectIds: string[]) {
    this.writeValue(objectIds);
    this.onTouched();
    this.onModelChanged(this.value);
  }

  onSearchTextChange(searchTerm: string): void {
    this.search.emit(searchTerm);
  }

  visualise() {
    this.showTree = !this.showTree;

    if (this.showTree) {
      const [selectedProperty] = (this.form.get('properties') as AbstractControl).value;
      this.loadTree.emit(selectedProperty.id);
    } else {
      this.loadTree.emit(null);
    }
  }

  ngOnDestroy(): void {}
}
